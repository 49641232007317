#crt:before{
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        to bottom,
        rgba(18, 16, 16, 0) 50%,
        #00000040 50%
    );
    background-size: 100% 4px;
    z-index: 9999999;
    pointer-events: none;
}

#crt{
    overflow: hidden
}

.scanline {
    width: 100%;
    height: 20px;
    z-index: 8;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 255, 255, 0.2) 10%,
        rgba(0, 0, 0, 0.1) 100%
    );
    opacity: 0.1;
    position: absolute;
    bottom: 100%;
    animation: scanline 5s linear infinite;
}

@keyframes scanline {
    0% {
        bottom: 100%;
        opacity: 0;
    }
    15% {
        bottom: 100%;
        opacity: 0;
    }
    16% {
        opacity: 0.5;
    }
    20% {
        bottom: 0%;
        opacity: 0.5;
    }
    21% {
        opacity: 0;
    }
    100% {
        bottom: 0%;
        opacity: 0;
    }
}


@media (pointer: fine) {
    body {
        overflow: scroll;
    }
    
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }
    
}